import React from 'react';


export function IconThumbsUp(props) {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path fill="#fff"
              d="M24.8985959,11.25 C26.3962559,11.25 27.8939158,11.8125 28.8299532,13.125 C29.7659906,14.0625 30.1404056,15.5625 29.9531981,17.0625 L28.4555382,25.6875 C28.0811232,28.125 26.0218409,30 23.5881435,30 L7.48829953,30 L7.48829953,15 C7.67550702,15 11.9812793,0.9375 11.9812793,0.9375 C11.9812793,0.375 12.5429017,0 13.1045242,0 C15.351014,0 16.8486739,0.9375 16.8486739,3.75 L16.8486739,11.25 L24.8985959,11.25 Z M1.59872116e-14,15 L3.74414977,15 L3.74414977,30 L1.59872116e-14,30 L1.59872116e-14,15 Z"/>
      </g>
    </svg>
  );
}
