import React from 'react';

import {Frame} from "./Frame";
import {CardSection} from "./CardSection";
import {
  Button,
  OriginDestinationColumn,
  TextInput,
  Dropdown,
  Phone, CheckCircle, Route, User, VehicleBusiness
} from '@yourmileag/ui-kit';

import QRCode from '../qrcode.js';
import {IconThumbsUp} from "./IconThumbsUp";
import {IconThumbsDown} from "./IconThumbsDown";
import {IconTaxi} from "./IconTaxi";

export function Status(props) {
  const [order, setOrder] = React.useState({});
  const [rating, setRating] = React.useState(0);

  const [ratingCategory, setRatingCategory] = React.useState('');
  const [ratingNotes, setRatingNotes] = React.useState('');

  let getOrderTimeout;

  React.useEffect(() => {
    var qrcodeEl = document.getElementById('qrcode');
    if (qrcodeEl) {
      new QRCode('qrcode', {
        text: window.location.href,
        width: 200,
        height: 200,
        colorDark: "#4D5B6A",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L
      });
    }
    return () => {
      if (qrcodeEl) {
        qrcodeEl.innerHTML = '';
      }
    }
  }, [order]);

  function getOrder() {
    var id = window.location.search.split('?go-id=')[1].split('&')[0];
    var url = props.config.api.url + '/essentials/orders/' + id;
    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('get', url);
    request.setRequestHeader('Authorization', 'Bearer ' + props.config.api.key);
    request.onload = function() {
      setOrder(request.response);
      if (typeof request.response.review != 'undefined') {
        if (request.response.review.experience === 'up') {
          setRating(2);
        } else if (request.response.review.experience === 'down') {
          if (request.response.review.reason || request.response.review.reasondesc) {
            setRating(2);
          } else {
            setRating(1);
          }
        }
      }

      if (!isExtended(request.response)) {
        getOrderTimeout = window.setTimeout(getOrder, 5000);
      }
    };
    request.send();
  }

  function ratePositive(event) {
    event.preventDefault();
    var id = window.location.search.split('?go-id=')[1].split('&')[0];
    var url = props.config.api.url + '/essentials/orders/' + id;
    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('PATCH', url);
    request.setRequestHeader('Authorization', 'Bearer ' + props.config.api.key);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.onload = function() {
      setRating(2);
    };
    request.send(JSON.stringify({
      review: {
        experience: 'up',
      }
    }));
  }

  function rateNegative(event) {
    event.preventDefault();
    var id = window.location.search.split('?go-id=')[1].split('&')[0];
    var url = props.config.api.url + '/essentials/orders/' + id;
    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('PATCH', url);
    request.setRequestHeader('Authorization', 'Bearer ' + props.config.api.key);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.onload = function() {
      if (ratingCategory || ratingNotes) {
        setRating(2);
      } else {
        setRating(1);
      }
    };
    if (ratingCategory || ratingNotes) {
      request.send(JSON.stringify({
        review: {
          experience: 'down',
          reason: ratingCategory,
          reasondesc: ratingNotes,
        }
      }));
    } else {
      request.send(JSON.stringify({
        review: {
          experience: 'down',
        }
      }));
    }
  }

  function hasOrder() {
    return !!order._id;
  }

  function isExtended(_order) {
    if (!_order) {
      _order = order;
    }
    return _order._id && ['prenew', 'new', 'assigning', 'problem'].indexOf(_order.status) === -1;
  }

  React.useEffect(() => {
    getOrder();
  }, []);

  if (!hasOrder()) {
    return null;
  }

  return (
    <div className={'slide'}>
      <Frame>
        <div className={'content'}>
          <div className={'card'}>
            {isExtended() ? (
              <h1>Deine Fahrt</h1>
            ) : (
              <div>
                <div className={'card__icon'}>
                  <IconTaxi/>
                </div>
                <h1>Wir suchen den passenden Fahrer für dich.</h1>
                <p>Diese Seite aktualisiert sich automatisch, sobald wir einen Fahrer für dich gefunden haben.</p>
              </div>
            )}
            {hasOrder() && (
              <CardSection title={'Deine Fahrstrecke'} icon={<Route/>}>
                <OriginDestinationColumn
                  origin={{
                    name: order.origin.name
                  }}
                  destination={{
                    name: order.destination.name
                  }}/>
              </CardSection>
            )}
            {isExtended() && (
              <div>
                {false && (
                  <CardSection title={'Dein Fahrer'} icon={<User/>}>
                    <div className={'card__section__text'}>
                      Marco Muster
                    </div>
                  </CardSection>
                )}
                <CardSection title={'Dein Fahrzeug'} icon={<VehicleBusiness/>}>
                  <div className={'card__section__text'}>
                    {order.car.manufacturer} {order.car.model}
                    <span>{order.car.licensePlate}</span>
                  </div>
                </CardSection>
              </div>
            )}
            <hr/>
            <h2>Hast du Fragen oder Probleme?</h2><br/>
            <Button onPress={() => window.location = 'tel:' + props.config.phone} size={'l'}
                    iconLeft={<Phone/>}>{props.config.phone}</Button>
            <hr/>
            <Button onPress={() => window.location = '?go-id'} size={'l'}>Neue Fahrt buchen</Button>
            {isExtended() && (
              <div>
                <hr/>
                {rating === 0 ? (
                  <div>
                    <h2>Bewerte dein Fahrererlebnis</h2><br/>
                    <button className={'rating'} onClick={ratePositive}>
                      <IconThumbsUp/>
                    </button>
                    <button className={'rating rating--bad'} onClick={rateNegative}>
                      <IconThumbsDown/>
                    </button>
                  </div>
                ) : rating === 1 ? (
                  <div>
                    <h2>Was ist schief gelaufen?</h2>
                    <div className={'form_row'}>
                      <Dropdown label={'Kategorie'} clean options={['App', 'Fahrzeug', 'Fahrer', 'Anderes']}
                                value={ratingCategory}
                                onSelect={(choice) => {
                                  setRatingCategory(choice);
                                }}/>
                    </div>
                    <div className={'form_row'}>
                      <TextInput type="textarea" label={'Bemerkung'} clean value={ratingNotes} onChange={(event) => {
                        setRatingNotes(event.target.value)
                      }}/>
                    </div>
                    <div className={'form_row form_row--center'}>
                      <Button className={'custom_button'} variant={'special'} size={'l'}
                              onPress={rateNegative}>Bewerten</Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={'card__icon'} style={{color: '#00B990'}}>
                      <CheckCircle/>
                    </div>
                    <h2>Herzlichen Dank für dein Feedback!</h2>
                  </div>
                )}
              </div>
            )}
            <div className={'mobile_qrcode'}>
              <hr/>
              <h2>Informationen auf dem Handy öffnen</h2>
              <div id={'qrcode'}/>
            </div>
          </div>
        </div>
      </Frame>
    </div>
  );
}
