import React from 'react';


export function IconPhone(props) {
  return (
    <svg width="100px" height="100px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="0%" y1="0.00146672115%" x2="100%" y2="99.9985333%" id="linearGradient-1">
          <stop stopColor="#00A0D9" offset="0%"/>
          <stop stopColor="#0074BC" offset="100%"/>
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M66,19 C67.5912989,19 69.1174224,19.632141 70.2426407,20.7573593 C71.3016696,21.8163883 71.9239243,23.2305443 71.9934663,24.7199274 L72,25 L72,75 C72,78.2383969 69.4344251,80.8775718 66.2249383,80.9958615 L66,81 L34,81 C30.7616031,81 28.1224282,78.4344251 28.0041385,75.2249383 L28,75 L28,25 C28,21.7616031 30.5655749,19.1224282 33.7750617,19.0041385 L34,19 L66,19 Z M66,21 L34,21 C31.8578046,21 30.1089211,22.6839685 30.0048953,24.8003597 L30,25 L30,75 C30,77.1421954 31.6839685,78.8910789 33.8003597,78.9951047 L34,79 L66,79 C68.1421954,79 69.8910789,77.3160315 69.9951047,75.1996403 L70,75 L70,25 C70,23.939134 69.5785726,22.9217184 68.8284271,22.1715729 C68.1318634,21.4750092 67.2048474,21.061881 66.2265818,21.0064166 L66,21 Z M50,64 C52.7614237,64 55,66.2385763 55,69 C55,71.7614237 52.7614237,74 50,74 C47.2385763,74 45,71.7614237 45,69 C45,66.2385763 47.2385763,64 50,64 Z M50,66 C48.3431458,66 47,67.3431458 47,69 C47,70.6568542 48.3431458,72 50,72 C51.6568542,72 53,70.6568542 53,69 C53,67.3431458 51.6568542,66 50,66 Z M53,26 L53,28 L47,28 L47,26 L53,26 Z"
          fill="#2C3E50" fillRule="nonzero"/>
        <ellipse id="notification" fill="url(#linearGradient-1)" cx="70" cy="18.9998533" rx="10"
                 ry="9.99985333"/>
      </g>
    </svg>
  );
}

