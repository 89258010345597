import React from 'react';


export function Frame(props) {
  return (
    <div className={'app__wrapper'}>
      <div
        className={'app' + (props.locked ? ' app--locked' : '') + (props.extended ? ' app--faded' : '')}>
        <div id={'app__positioner'} style={{'margin-top': props.locked ? props.position * -1 : 0}}/>
        <div className={'app__main'}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
