import React from 'react';


export function CardSection(props) {
  return (
    <div className={'card__section'}>
      <div className={'card__section__title'}>
        <div className={'card__section__title__icon'}>
          {props.icon}
        </div>
        {props.title}
      </div>
      <div className={'card__section__content'}>
        {props.children}
      </div>
    </div>
  );
}
