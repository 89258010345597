import React from 'react';


export function IconReload(props) {
  return (
    <svg width="22px" height="18px" viewBox="0 0 22 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M11,0.05 C6.05705149,0.05 2.05,4.05705149 2.05,9 L2.05713816,9.35990133 C2.10466187,10.5564735 2.38884394,11.7200847 2.89048093,12.7915883 L3.012,13.038 L1.23759951,12.6607863 L1.11299079,12.6431616 C0.63117567,12.612516 0.208985653,12.9303762 0.110759779,13.3924926 C0.00167464596,13.9056979 0.329278691,14.4101638 0.842483894,14.519249 L5.26907462,15.4601499 L5.39359994,15.4777568 C5.87541506,15.5084024 6.29760508,15.1905422 6.39583095,14.7284258 L7.33673186,10.301835 L7.35433875,10.1773097 C7.38498439,9.6954946 7.06712414,9.27330458 6.60500774,9.17507871 L6.48048242,9.15747181 L6.36142381,9.15695274 C5.93055205,9.18080466 5.5682918,9.48319612 5.47825141,9.90680282 L4.913,12.558 L4.85356521,12.4564849 C4.26284809,11.4087667 3.95,10.2243147 3.95,9 C3.95,5.10639251 7.10639251,1.95 11,1.95 C14.8936075,1.95 18.05,5.10639251 18.05,9 C18.05,12.8936075 14.8936075,16.05 11,16.05 C10.4753295,16.05 10.05,16.4753295 10.05,17 C10.05,17.5246705 10.4753295,17.95 11,17.95 C15.9429485,17.95 19.95,13.9429485 19.95,9 C19.95,4.05705149 15.9429485,0.05 11,0.05 Z"
          fill="#979797" fillRule="nonzero"/>
      </g>
    </svg>
  );
}
