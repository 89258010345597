import React from 'react';


export function IconReverse(props) {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M13.9893401,18.3396821 L13.9142395,18.4215918 L11.4642395,20.7292841 C11.2042781,20.9741457 10.8131479,20.9986319 10.5269528,20.8027426 L10.4357647,20.7292841 L7.98576474,18.4215918 C7.68424562,18.1375863 7.67004799,17.6629249 7.95405344,17.3614058 C8.21224021,17.0872975 8.62799393,17.050645 8.92798751,17.2596237 L9.01423946,17.3296945 L10.2006709,18.4463333 L10.2000031,10.600001 C10.2000031,10.1857875 10.5357896,9.85000105 10.9500031,9.85000105 C11.3296989,9.85000105 11.6434941,10.1321549 11.6931565,10.4982305 L11.7000031,10.600001 L11.7006709,18.4463333 L12.8857647,17.3296945 C13.159873,17.0715077 13.5770742,17.0597697 13.864041,17.2863051 L13.9459508,17.3614058 C14.2041375,17.6355141 14.2158755,18.0527152 13.9893401,18.3396821 Z M21.4560068,12.0269867 C21.6573715,12.2820684 21.670471,12.6400512 21.4895632,12.9089688 L21.4126174,13.005263 L21.3307077,13.0803636 C21.075626,13.2817284 20.7176432,13.2948279 20.4487256,13.11392 L20.3524314,13.0369743 L19.1666709,11.9193333 L19.1666698,19.7666677 C19.1666698,20.1808813 18.8308834,20.5166677 18.4166698,20.5166677 C18.0369741,20.5166677 17.7231789,20.2345138 17.6735164,19.8684383 L17.6666698,19.7666677 L17.6666709,11.9203333 L16.4809061,13.0369743 L16.3946542,13.107045 C16.0946606,13.3160238 15.6789069,13.2793713 15.4207201,13.005263 C15.1625333,12.7311547 15.1507954,12.3139536 15.3773308,12.0269867 L15.4524314,11.945077 L17.9024314,9.63738467 L17.9936194,9.56392619 C18.2480151,9.38980238 18.5853224,9.38980238 18.8397181,9.56392619 L18.9309061,9.63738467 L21.3809061,11.945077 L21.4560068,12.0269867 Z"
          fill="#2C3E50" fillRule="nonzero"/>
      </g>
    </svg>
  );
}