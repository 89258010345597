import React from 'react';


export function IconThumbsDown(props) {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path fill="#fff"
              d="M5.10140406,18.75 C3.60374415,18.75 2.10608424,18.1875 1.1700468,16.875 C0.23400936,15.9375 -0.140405616,14.4375 0.0468018721,12.9375 L1.54446178,4.3125 C1.91887676,1.875 3.97815913,0 6.41185647,0 L22.5117005,0 L22.5117005,15 C22.324493,15 18.0187207,29.0625 18.0187207,29.0625 C18.0187207,29.625 17.4570983,30 16.8954758,30 C14.648986,30 13.1513261,29.0625 13.1513261,26.25 L13.1513261,18.75 L5.10140406,18.75 Z M30,15 L26.2558502,15 L26.2558502,0 L30,0 L30,15 Z"/>
      </g>
    </svg>
  );
}
