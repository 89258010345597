import React from 'react';

import {TextInput} from '@yourmileag/ui-kit';


export function ExtendableField(props) {
  const [result, setResult] = React.useState({features: []});
  const [focusIndex, setFocusIndex] = React.useState(0);

  function onChange(event) {
    var value = event.target.value;
    props.onChange(value);
    var url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + value + '.json?country=ch&language=de&access_token=pk.eyJ1IjoieW91cm1pbGUiLCJhIjoiY2p5aTZ5NXpvMDZsMTNubzdrNjFyM2dweSJ9.U5UbUUffC4e1WTKk-42yaQ'

    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('get', url);
    request.onload = function() {
      setResult(request.response);
    };
    request.send();
  }

  function onKeyDown(event) {
    var keyCode = event.keyCode || event.which;
    if (keyCode == 38) {
      event.preventDefault();
      if (focusIndex == 0) {
        setFocusIndex(result.features.length - 1);
      } else {
        setFocusIndex(focusIndex - 1);
      }
    } else if (keyCode == 40) {
      event.preventDefault();
      if (focusIndex == result.features.length - 1) {
        setFocusIndex(0);
      } else {
        setFocusIndex(focusIndex + 1);
      }
    } else if (keyCode == 13) {
      event.preventDefault();
      if (result.features.length > 0) {
        props.onSelect(result.features[focusIndex]);
        const target = event.target
        window.setTimeout(function() {
          target.blur();
        }, 50);
      }
    } else if (keyCode == 27) {
      event.preventDefault();
      event.target.blur();
    } else {
      setFocusIndex(0);
    }
  }

  return (
    <div className={'form_row'}>
      <div className={'extendable_field' + (props.extended ? ' extendable_field--extended' : '')}
           style={props.inputRect ? {
             'position': 'absolute',
             'top': JSON.parse(props.inputRect).top,
             'left': 30,
             'right': 30,
             'zIndex': 1,
           } : {}}>
        <div className={'extendable_field__input'}>
          <TextInput required value={props.value} label={props.label} clean onFocus={props.onExtend}
                     onBlur={props.onCollapse} onChange={onChange} onKeyDown={onKeyDown} tabIndex={props.tabIndex}/>
        </div>
        {result.features && (
          <ul className={'extendable_field__list'}>
            {result.features.map((feature, index) => {
              return (
                <li>
                  <a href={'#'} onClick={() => props.onSelect(feature)} tabIndex={'-1'} class={focusIndex == index ? 'focus' : ''}>
                    <span dangerouslySetInnerHTML={{__html: '<b>' + feature.place_name.replace(',', '</b>,')}}/>
                  </a>
                </li>
              )
            })}
          </ul>
        )}
      </div>
      <span className={'extendable_field__dummy'}
            style={{'display': props.inputRect ? 'block' : 'none'}}></span>
    </div>
  );
}
