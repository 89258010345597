import React from 'react';

import {Order} from "./components/Order";
import {Status} from "./components/Status";
import {Crossmark} from '@yourmileag/ui-kit';

function Widget(props) {
  const order = window.location.search.indexOf('?go-id=') >= 0

  const [open, setOpen] = React.useState(order);

  React.useEffect(() => {
    var triggers = document.getElementsByClassName('open_go_widget');

    for (var i = 0; i < triggers.length; i++) {
      triggers[i].addEventListener('click', openWidget, false);
    }
    return () => {
      for (var i = 0; i < triggers.length; i++) {
        triggers[i].removeEventListener('click', openWidget);
      }
    }
  }, []);

  function openWidget(event) {
    setOpen(true);
  }

  function closeWidget(event) {
    event.preventDefault();
    setOpen(false);
  }

  return (
    <div className={'widget' + (open ? ' widget--open' : '') + (props.config.fms ? ' widget--fms' : '')}>
      <style
        dangerouslySetInnerHTML={{__html: '.custom_button {background-image: linear-gradient(to right, ' + props.config.ui.colorLight + ', ' + props.config.ui.colorDark + ') !important;} .custom_button[disabled] {opacity: 0.4;}'}}/>
      <a href="." onClick={closeWidget} className={'widget__close'}></a>
      <div className={'widget__frame'}>
        <div className={'widget__main'}>
          <div className={'widget__content'}>
            <div className={'widget__header'}>
              <img className={'widget__header__brand'} src={props.config.ui.logo}/>
              &nbsp;
              <a href="." onClick={closeWidget} className={'widget__header__close'}>
                <Crossmark/>
              </a>
            </div>
            {props.config.fms && (
              <iframe src="https://www.airport-zurich.taxi/de/order/"></iframe>
            )}
            {order ? (
              <Status config={props.config}/>
            ) : (
              <Order config={props.config}/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Widget;
