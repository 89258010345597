import React from 'react';
import parsePhoneNumber from 'libphonenumber-js'
import moment from 'moment';
import { Clock, Calendar, Button, TextInput, FleetDropdown, Dropdown, DateInput } from '@yourmileag/ui-kit';
import { ExtendableField } from "./ExtendableField";
import { Frame } from "./Frame";
import { IconPhone } from "./IconPhone";
import { IconReverse } from "./IconReverse";
import { IconReload } from "./IconReload";

export function Order(props) {
  const contentRectEl = React.useRef();
  const confirmInputEl = React.useRef();

  const [orderHash, setOrderHash] = React.useState(null);

  const [phone, setPhone] = React.useState('');

  const [salutation, setSalutation] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');

  const [originQuery, setOriginQuery] = React.useState('');
  const [origin, setOrigin] = React.useState({
    center: [],
    place_name: '',
  });

  const [destinationQuery, setDestinationQuery] = React.useState('');
  const [destination, setDestination] = React.useState({
    center: [],
    place_name: '',
  });

  const [time, setTime] = React.useState(moment().format('HH:mm'));
  const [date, setDate] = React.useState(moment());

  const [confirmCode, setConfirmCode] = React.useState('');

  const [fleets, setFleets] = React.useState([]);
  const [fleet, setFleet] = React.useState(null);

  const [notes, setNotes] = React.useState('');

  const [duration, setDuration] = React.useState('–');
  const [price, setPrice] = React.useState('–');

  const [confirm, setConfirm] = React.useState(false);
  const [codeStatus, setCodeStatus] = React.useState(0);
  const [reversed, setReversed] = React.useState(false);

  const [locked, setLocked] = React.useState(false);
  const [position, setPosition] = React.useState(0);

  const [extendedOrigin, setExtendedOrigin] = React.useState(false);
  const [inputRectOrigin, setInputRectOrigin] = React.useState('');

  const [extendedDestination, setExtendedDestination] = React.useState(false);
  const [inputRectDestination, setInputRectDestination] = React.useState('');


  function onExtend(event, type) {
    const target = event.target;

    window.setTimeout(function () {
      // TODO: scroll to previous scroll position of embedded-in page / maybe lock embedded-in page
      window.scrollTo(0, 0);

      setLocked(true);
      setPosition(window.scrollY);
      let inputRect = JSON.parse(JSON.stringify(target.parentNode.parentNode.getBoundingClientRect()))
      inputRect.top = inputRect.top - contentRectEl.current.getBoundingClientRect().top
      inputRect = JSON.stringify(inputRect)
      if (type === 'origin') {
        setInputRectOrigin(inputRect);
      } else {
        setInputRectDestination(inputRect);
      }

      window.requestAnimationFrame(function () {
        if (type === 'origin') {
          setExtendedOrigin(true);
        } else {
          setExtendedDestination(true);
        }

        window.requestAnimationFrame(function () {
          target.select();
        });
      });
    }, 100);
  }

  function onCollapse(event, type) {
    if (extendedOrigin || extendedDestination) {
      if (type === 'origin') {
        setExtendedOrigin(false);
        setOriginQuery(origin.place_name);
      } else {
        setExtendedDestination(false);
        setDestinationQuery(destination.place_name);
      }

      window.setTimeout(function () {
        if (type === 'origin') {
          setInputRectOrigin('');
        } else {
          setInputRectDestination('');
        }

        const _position = position;

        setLocked(false);
        setPosition(0);

        window.requestAnimationFrame(function () {
          document.documentElement.scrollTop = _position;
          document.body.scrollTop = _position;
        });
      }, 500);
    }
  }

  function confirmOrder(code) {
    if (!code) {
      code = confirmCode;
    }
    var url = props.config.api.url + '/essentials/verify/' + orderHash;
    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('post', url);
    request.setRequestHeader('Authorization', 'Bearer ' + props.config.api.key);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.onload = function () {
      if (request.response) {
        console.log(request.response);
        if (request.response.status === 'ok') {
          setCodeStatus(2);
          window.setTimeout(function () {
            window.location.search = '?go-id=' + orderHash.split('.')[orderHash.split('.').length - 1]
          }, 500);
        } else {
          setCodeStatus(1);
        }
      } else {
        setCodeStatus(1);
      }
    };
    request.send(JSON.stringify({ otp: parseInt(code) }));
  }

  function onConfirmChange(event) {
    var target = event.target;
    var value = target.value;
    setConfirmCode(value);
    if (value.length === parseInt(target.maxLength)) {
      confirmOrder(value);
    } else {
      setCodeStatus(0);
    }
  }

  function resendCode() {
    var id = orderHash.split('.')[orderHash.split('.').length - 1];
    var url = props.config.api.url + '/essentials/resend/' + id;
    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('get', url);
    request.setRequestHeader('Authorization', 'Bearer ' + props.config.api.key);
    request.onload = function () {
      setOrderHash(request.response.hash);
      setCodeStatus(3);
    };
    request.send();
  }

  function reverseWaypoints() {
    setReversed(true);
    let tmp = origin;
    let tmpQuery = originQuery
    setOrigin(destination);
    setOriginQuery(destinationQuery);
    setDestination(tmp);
    setDestinationQuery(tmpQuery);
    window.setTimeout(function () {
      setReversed(false);
    }, 500);
  }

  function onPhoneChange(event) {
    const value = event.target.value;
    setPhone(value);
  }

  function validPhone(value) {
    const phoneNumber = parsePhoneNumber(value, 'CH');
    if (phoneNumber) {
      return phoneNumber.isValid();
    }
    return false;
  }

  function getFleets() {
    var url = props.config.api.url + '/essentials/fleets';
    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('get', url);
    request.setRequestHeader('Authorization', 'Bearer ' + props.config.api.key);
    request.onload = function () {
      let newFleets = request.response.items.map(function (item) {
        delete item.baggageCount;
        return item;
      });
      setFleets(newFleets);
      setFleet(newFleets[0]);
    };
    request.send();
  }

  function getRoute(callback) {
    var url = 'https://api.mapbox.com/directions/v5/mapbox/driving/' + origin.center.join(',') + ';' + destination.center.join(',') + '?geometries=geojson&overview=full&steps=true&access_token=pk.eyJ1IjoieW91cm1pbGUiLCJhIjoiY2p5aTZ5NXpvMDZsMTNubzdrNjFyM2dweSJ9.U5UbUUffC4e1WTKk-42yaQ'
    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('get', url);
    request.onload = function () {
      if (typeof request.response.routes !== 'undefined') {
        var route = request.response.routes[0];
        callback(route);
      } else {
        callback(null);
      }
    };
    request.send();
  }

  function order() {
    getRoute(function (route) {
      postOrder(route);
    });
  }

  function calcPrice(route) {
    return Math.ceil(parseFloat(fleet.rateBaseFee) + (parseFloat(fleet.ratePerKm) * (route.distance / 1000)));
  }

  function getTimeAndDateAsUnix(time, date) {
    const [hours, minutes] = time.split(':');

    return moment(date)
      .startOf('day')
      .add(hours, 'hours')
      .add(minutes, 'minutes')
      .unix();
  };

  function postOrder(route) {
    var url = props.config.api.url + '/essentials/orders'
    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('post', url);
    request.setRequestHeader('Authorization', 'Bearer ' + props.config.api.key);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.onload = function () {
      if (true) {
        const locationArray = request.getResponseHeader('location').split('/');
        const orderId = locationArray[locationArray.length - 1];
        setCodeStatus(2);
        window.setTimeout(function () {
          window.location.search = '?go-id=' + orderId
        }, 500);
        return;
      }
      setOrderHash(request.response.hash);
      setConfirm(true);
      window.setTimeout(function () {
        confirmInputEl.current.focus();
      }, 500);
    };

    var distance = route.distance;
    var duration = Math.round(route.duration / 60);

    var startTimestamp = getTimeAndDateAsUnix(time, date);
    if (startTimestamp < moment().unix()) {
      startTimestamp = moment().unix();
    }

    var data = {
      origin: {
        name: origin.place_name,
        position: JSON.stringify({
          longitude: origin.center[0],
          latitude: origin.center[1],
        }),
        source: origin.place_name,
      },
      destination: {
        name: destination.place_name,
        position: JSON.stringify({
          longitude: destination.center[0],
          latitude: destination.center[1],
        }),
        source: destination.place_name,
      },
      stopovers: [],
      customer: {
        firstName: firstName,
        lastName: lastName,
        phone: parsePhoneNumber(phone, 'CH').format('E.164'),
      },
      fleet: fleet,
      estimatedDuration: duration,
      estimatedDistance: distance,
      mapboxGeoJson: {
        coordinates: route.geometry.coordinates,
        distance: {
          value: distance,
          text: Math.round(distance / 1000) + ' km',
        },
        duration: {
          value: duration,
          text: duration + ' Minuten',
        }
      },
      pricing: 'taxometer',
      currency: 'CHF',
      isPaid: false,
      hasInvoiceBeenSent: false,
      status: 'new',
      stage: 0,
      source: 'widget',
      estimatedTimespan: {
        startTimestamp: startTimestamp,
        endTimestamp: startTimestamp + route.duration,
      },
      estimatedPrice: calcPrice(route),
      noteText: notes,
      source: 'widget',
    };

    request.send(JSON.stringify(data));
  }

  React.useEffect(() => {
    getFleets();
  }, []);

  React.useEffect(() => {
    getRoute(function (route) {
      if (route) {
        setDuration(Math.round(route.duration / 60) + ' Min');
        setPrice('CHF ' + calcPrice(route) + '.-');
      } else {
        setDuration('–');
        setPrice('–');
      }
    });
  }, [origin, destination, fleet]);

  return [
    <div className={'slide' + (confirm ? ' slide--done' : '')}>
      <Frame locked={locked} extended={extendedOrigin || extendedDestination} position={position}>
        <div className={'content'} ref={contentRectEl}>
          <fieldset>
            <div className={'form_row'}>
              <TextInput name={'phone_number'} type={'tel'} label={'Telefonnummer'} clean required
                errorMessage={phone && !validPhone(phone) ? 'Die Telefonnummer ist nicht gültig.' : false}
                value={phone} onChange={onPhoneChange} tabIndex={confirm ? '-1' : null} />
            </div>
            {false && (
              <div className={'form_row'}>
                <Dropdown label={'Anrede'} clean options={['Herr', 'Frau']} value={salutation}
                  onSelect={(option) => {
                    setSalutation(option);
                  }} />
              </div>
            )}
            <div className={'form_row form_row--split'}>
              <TextInput name={'first_name'} label={'Vorname'} clean required
                value={firstName} tabIndex={confirm ? '-1' : null}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }} />
            </div>
            <div className={'form_row form_row--split'}>
              <TextInput name={'last_name'} label={'Nachname'} clean required
                value={lastName} tabIndex={confirm ? '-1' : null}
                onChange={(event) => {
                  setLastName(event.target.value);
                }} />
            </div>
          </fieldset>

          <fieldset>
            <div className={'form_row form_row--split'}>
              <DateInput
                id="filter-date"
                label={'Datum'}
                value={date}
                onChange={(value) => setDate(value)}
                showClearDate={false}
                isOutsideRange={() => { }}
                numberOfMonths={1}
                clean
                placeholder={'datum'}
                iconLeft={<Calendar />}
              />
            </div>
            <div className={'form_row form_row--split'}>
              <TextInput
                clean
                type="time"
                id="oc-time"
                iconLeft={<Clock />}
                label={'Zeit'}
                value={time}
                onChange={(e) => { setTime(e.target.value) }}
                required
                errorMessage={false}
              />
            </div>
          </fieldset>

          <fieldset>
            <ExtendableField value={originQuery} label={'Start'} extended={extendedOrigin}
              inputRect={inputRectOrigin}
              tabIndex={confirm ? '-1' : null}
              onChange={(value) => {
                setOriginQuery(value);
              }}
              onSelect={(value) => {
                setOriginQuery(value.place_name);
                setOrigin(value);
              }}
              onExtend={(event) => {
                onExtend(event, 'origin')
              }}
              onCollapse={(event) => {
                onCollapse(event, 'origin')
              }} />
            <button onClick={reverseWaypoints} tabIndex={'-1'}
              className={'waypoints__reverse' + (reversed ? ' waypoints__reverse--reversed' : '')}>
              <IconReverse />
            </button>
            <ExtendableField value={destinationQuery} label={'Ziel'} extended={extendedDestination}
              inputRect={inputRectDestination}
              tabIndex={confirm ? '-1' : null}
              onChange={(value) => {
                setDestinationQuery(value);
              }}
              onSelect={(value) => {
                setDestinationQuery(value.place_name);
                setDestination(value);
              }}
              onExtend={(event) => {
                onExtend(event, 'destination')
              }}
              onCollapse={(event) => {
                onCollapse(event, 'destination')
              }} />
          </fieldset>

          <fieldset>
            <div className={'form_row'}>
              <FleetDropdown required value={fleet} label={'Taxiflotte'} clean options={fleets} setValue={(choice) => {
                setFleet(choice)
              }} />
            </div>
          </fieldset>

          <fieldset>
            <div className={'form_row'}>
              <TextInput
                clean
                type="textarea"
                id="oc-notes"
                label={'Bemerkungen'}
                value={notes}
                onChange={(e) => { setNotes(e.target.value) }}
                errorMessage={false}
              />
            </div>
          </fieldset>

          <ul className={'stats'}>
            <li>
              Fahrzeit
              <b>{duration}</b>
            </li>
            <li>
              Fixpreis
              <b>{price}</b>
            </li>
          </ul>
          <Button className={'custom_button'} tabIndex={confirm ? '-1' : null}
            disabled={confirm || !(validPhone(phone) && firstName && lastName && originQuery && destinationQuery && fleet)}
            variant={'special'}
            onPress={order} size={'l'}>
            Jetzt buchen
          </Button>
          <p className={'legal'}>Mit dem Klick auf «Jetzt buchen» stimmen Sie den <a href="#agb">AGB</a> zu.</p>
        </div>
      </Frame>
    </div>,
    <div className={'slide'}>
      <div className={'content'}>
        <div className={'card__icon'}>
          <IconPhone />
        </div>
        <h1>Bitte bestätige deine Fahrt.</h1>
        <p>Wir haben dir eine SMS mit dem Bestätigungscode für deine Fahrt gesendet.</p>
        <div className={'code_input__frame'}>
          <div
            className={'code_input' + (codeStatus === 1 ? ' code_input--error' : '') + (codeStatus === 2 ? ' code_input--success' : '')}>
            <input ref={confirmInputEl} type={'text'} maxLength={6} onChange={onConfirmChange} inputmode={'numeric'}
              pattern={'[0-9]*'} value={confirmCode} tabIndex={confirm ? null : '-1'}
              autocomplete={'one-time-code'} />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
        <p><br /></p>
        <Button className={'custom_button'} tabIndex={confirm ? null : '-1'}
          variant={'special'} onPress={() => confirmOrder()} size={'l'}>
          Bestätigen
        </Button>
        <p><br /></p>
        <p className={'code_input__status'}>
          {codeStatus === 1 ? (<span>Dieser Code ist nicht korrekt</span>) : codeStatus === 3 ? (
            <span>Der Code wurde erneut gesendet</span>) : (<span>&nbsp;</span>)}
        </p>
        <a href={'#'} className={'link'} onClick={() => resendCode()} tabIndex={confirm ? null : '-1'}>
          <IconReload />
          Code erneut senden
        </a>
      </div>
    </div>
  ];
}
