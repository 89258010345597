import React from 'react';
import ReactDOM from 'react-dom';

import './styles/reset.css';
import './styles/fonts.css';
import './styles/index.css';
import './styles/widget.css';
import './styles/app.css';
import './styles/form.css';
import './styles/card.css';
import './styles/typo.css';
import './styles/waypoints.css';
import './styles/slide.css';
import './styles/stats.css';

import Widget from './Widget';

let widgetEl = document.getElementById('go_widget')

let key = widgetEl.getAttribute('data-key')
let fms = widgetEl.getAttribute('data-fms') == 'true'
let logo = widgetEl.getAttribute('data-logo')
let phone = widgetEl.getAttribute('data-phone')
let colorLight = widgetEl.getAttribute('data-color-light')
let colorDark = widgetEl.getAttribute('data-color-dark')

const config = {
  fms: fms,
  api: {
    url: 'https://api.go-fleet.ch',
    key: key,
  },
  phone: phone,
  ui: {
    logo: logo,
    colorLight: colorLight,
    colorDark: colorDark,
  }
}

ReactDOM.render(<Widget config={config}/>, widgetEl);
